const servicesData = [
  {
    serviceName: "Navette Aeroport",
    img: "/services/navette-aeroport.jpeg",
    describingText: "Des navettes vers et depuis les aéroports",
  },
  {
    serviceName: "Navette Gare",
    img: "/services/navette-gare.jpeg",
    describingText: "Des navettes vers et depuis les gares",
  },
  {
    serviceName: "Navette Port",
    img: "/services/navette-port.jpeg",
    describingText: "Des navettes vers et depuis les ports",
  },
  {
    serviceName: "Transport Hotel",
    img: "/services/navette-hotel.jpeg",
    describingText:
      "Des transferts vers et depuis les hôtels, quelle que soit leur localisation",
  },
  {
    serviceName: "Voyages d'affaires",
    img: "/services/voyage-daffaires.jpeg",
    describingText:
      "Des voyages d'affaires personnalisés, adaptés à vos besoins et à votre budget",
  },
  {
    serviceName: "Déplacements toutes distances",
    img: "/services/voyage-toutes-distances.jpeg",
    describingText: "Des déplacements de toutes distances, partout en France",
  },
];

export default servicesData;
