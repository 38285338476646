import { React } from "react";
import SliderData from "../data/sliderData.js";
import ImageSlider from "../components/ImageSlider.js";

function Events() {
  return (
    <div className="events">
      <h1>NOS EVENEMENTS</h1>
      <h2>
        Nous avons une longue expérience dans l'organisation de transports.
      </h2>
      <p>
        Pour des évènements prestigieux tels que les festivals de cinéma
        (Festival de Cannes, Festival de Deauville), des évènements sportifs,
        culturels, professionnels (salons, conférences,
        séminaires).
      </p>

      <ImageSlider className="slider" SliderData={SliderData} />
    </div>
  );
}

export default Events;
