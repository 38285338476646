import "./App.css";
import TopBanner from "./components/TopBanner.js";
import Home from "./pages/Home.js";
import Services from "./pages/Services.js";
import OurCars from "./pages/OurCars.js";
import Events from "./pages/Events.js";
import Contact from "./pages/Contact.js";
import Reservation from "./pages/Reservation.js";
import BottomBanner from "./components/BottomBanner.js";

function App() {
  return (
    <div className="App">
      <TopBanner />
      <Home />
      <Services />
      <OurCars />
      <Events />
      <Contact />
      <Reservation />
      <BottomBanner />
    </div>
  );
}

export default App;
