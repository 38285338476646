import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";

function Contact() {
  return (
    <div className="contact">
      <h1>CONTACT</h1>
      <div className="info">
        <div className="call-us">
          <p>
            Appelez nous
            <br />
            <a href="tel:+33752394415" className="phone-call-icon">
              <FaPhoneVolume className="icon" color="#3ba43b" />
            </a>
            <br />
            +(33)752394415
          </p>
        </div>

        <div className="write-us">
          <p>
            Envoyez nous un message sur WhatsApp{" "}
            <a
              href="https://wa.me/+33752394415"
              className="phone-whatsapp-message"
              target="_blank"
              rel="noopener noreferrer"
            >
              <br /> <FaWhatsapp className="icon" color="#3ba43b" />
            </a>
          </p>
        </div>
        <div className="email-us">
          <p>
            Ecrivez nous un mail
            <br />
            <MdOutlineMailOutline
              className="email-icon"
              onClick={() => (window.location = "mailto:a.h.drive59@gmail.com")}
              color="#3ba43b"
            />
            <br />
            a.h.drive59@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
