const carsData = [
  {
    carModel: "Class S",
    img: "/cars/mercedes-s-class.jpeg",
    description:
      " La limousine de Mercedes-Benz. Elle est la quintessence du luxe et du confort. Elle est parfaite pour les personnes qui recherchent le summum du raffinement.",
  },
  {
    carModel: "Van",
    img: "/cars/mercedes-v-class.jpeg",
    description:
      "Le van de luxe de Mercedes-Benz. Elle offre un espace généreux et un confort inégalé. Elle est idéale pour les familles nombreuses ou les professionnels qui ont besoin d'un véhicule spacieux et confortable.",
  },
  {
    carModel: "Class E",
    img: "/cars/mercedes-e-class.jpeg",
    description:
      "La berline haut de gamme de Mercedes-Benz. Elle est synonyme de luxe, de raffinement et de performance. Elle est idéale pour les personnes qui recherchent le meilleur de la voiture de luxe.",
  },
  {
    carModel: "Class UQV",
    img: "/cars/mercedes-uqv-class.jpeg",
    description:
      "Le SUV de Mercedes-Benz. Elle allie le luxe, la performance et l'écologie. Elle est parfaite pour les personnes qui recherchent une voiture luxueuse et écologique.",
  },
];

export default carsData;
