import React from "react";

function BottomBanner() {
  return (
    <div className="bottom-banner">
      All rights reserved{" "}&#20; <b> <i>Elite Driving Services @{new Date().getFullYear()}</i></b> &#20; | Created by &#20;
      <a href="https://assirem-dev.com/" target="_blank" rel="noreferrer">
        Assirem DEV
      </a>&#20;{" "}
      
    </div>
  );
}

export default BottomBanner;
