import React from "react";
import ServiceCard from "../components/ServiceCard.js";
import servicesData from "../data/servicesData.js";

function Services() {
  return (
    <div className="services">
      <h1>Nos Services</h1>
      <h2>
        On vous propose une gamme de services de transport haut de gamme,
        adaptés à tous vos besoins.
      </h2>{" "}
      <p>
        Nos chauffeurs sont expérimentés et ils vous assureront un trajet
        agréable et confortable.
      </p>
      <div className="cards">
        {servicesData?.map((service, idx) => (
          <ServiceCard
            serviceName={service.serviceName}
            img={service.img}
            describingText={service.describingText}
            key={idx}
          />
        ))}
        {" "}
      </div>
    </div>
  );
}

export default Services;
