import CarCard from "../components/CarCard.js";
import carsData from "../data/cardsData.js";

function OurCars() {
  return (
    <div className="our-cars">
      <h1>NOS VEHICULES</h1>
      <h2>Le luxe et la performance au service du transport privé.</h2>
      <p>
        Nous mettons à votre disposition une flotte de véhicules haut de gamme,
        des chauffeurs expérimentés et un service personnalisé.{" "}
      </p>
      <div className="cars">
        {carsData?.map((car, idx) => (
          <CarCard
            title={car.carModel}
            img={car.img}
            description={car.description}
            key={idx}
          />
        ))}
        
      </div>
    </div>
  );
}

export default OurCars;
