import React from "react";
import { Link } from "react-scroll";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <ul>
        <li className="nav-item">
          <Link
            to="body"
            spy={true}
            smooth={true}
            offset={-600}
            duration={500}
            tabIndex="1"
          >
            {" "}
            Home{" "}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            tabIndex="2"
          >
            {" "}
            Nos Services{" "}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="cars"
            spy={true}
            smooth={true}
            offset={-400}
            duration={500}
            tabIndex="3"
          >
            {" "}
            Nos Véhicules{" "}
          </Link>
        </li>{" "}
        <a href="/">
          <img className="logo" src={"logo.png"} alt="" />
        </a>
        <li className="nav-item">
          <Link
            to="events"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            tabIndex="4"
          >
            {" "}
            Nos Évènements{" "}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            tabIndex="5"
          >
            Contact
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="reservation"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            tabIndex="6"
          >
            Devis & Réservation
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
