import React from "react";

function Home() {
  return (
    <div className="body">
      <img src={"drivers.jpeg"} alt="" />

      <div className="intro">
        <p className="home-title">&mdash;Elite Driving Services&mdash;</p>
        <div className="home-paragraph">
          <p>
            {" "}
            Nous sommes une entreprise de transport de luxe spécialisée dans
            l'organisation et la réalisation de déplacements. <br />
            Nous sommes à votre disposition pour répondre à vos questions et
            vous accompagner dans l'organisation de votre déplacement.
            <br />
            Nous nous engageons à vous offrir un service de qualité, conforme à
            vos exigences.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
