import React from "react";
import "./ServiceCard.css";

function Service({ serviceName, describingText, img }) {
  return (
    <div className="card">
      <div className="card-title">{serviceName}</div>
      <img src={img} alt="" />
      <div className="text">
        <p>{describingText}</p>
      </div>
    </div>
  );
}

export default Service;
