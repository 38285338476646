import React from "react";
import "./CarCard.css";

function Car({ title, img, description }) {
  return (
    <div className="car">
      <div className="title">{title}</div>
      <img src={img} alt="" />
      <div className="description">
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Car;
