import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { PhoneInput } from "react-international-phone";
import { RiTaxiLine } from "react-icons/ri";
import { BiSolidTaxi } from "react-icons/bi";
import { IoMdTime } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import "react-international-phone/style.css";

function Reservation() {
  const form = useRef();
  const recaptcha = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/verify`, {
        method: "POST",
        body: JSON.stringify({ captchaValue }),
        headers: {
          "content-type": "application/json",
        },
      });
      const data = await res.json();

      if (data.success) {
        emailjs
          .sendForm(
            `${process.env.REACT_APP_SERVICE_ID}`,
            `${process.env.REACT_APP_TEMPLATE_ID}`,
            form.current,
            `${process.env.REACT_APP_ACCESS_TOKEN}`
          )
          .then(
            (result) => {
              console.log(result.text);
              alert("Merci pour votre message");
            },
            (error) => {
              console.log(error.text);
            }
          );
      } else {
        alert("reCAPTCHA validation failed! ");
      }
    }
  };

  return (
    <div className="reservation">
      <h1>RECEVOIR UN DEVIS</h1>

      <form ref={form} onSubmit={sendEmail}>
        <div className="inputs">
          <input
            type="text"
            id="name"
            name="user_name"
            placeholder="NOM COMPLET"
          />
          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="EMAIL"
          />
          <PhoneInput
            defaultCountry="fr"
            name="user_phone"
            className="PhoneInput"
          />
          <div className="line">
            <input
              type="number"
              id="number-of-passengers"
              name="number_of_passengers"
              placeholder="nombre de passagers"
              min="1"
              max="100"
            />
            <FaPeopleGroup size={"1.5rem"} color="#3ba43b" />
          </div>
          <div className="line">
            <input
              type="datetime-local"
              id="datetime-local"
              name="time_and_date"
              placeholder="DATE ET HORARIRE"
            />
            <IoMdTime size={"1.5rem"} color="#3ba43b" />
          </div>
          <div className="line">
            {" "}
            <input
              type="text"
              id="from-adress"
              name="from_adress"
              autoComplete="on"
              placeholder="ADRESSE DE DEPART"
            />{" "}
            <RiTaxiLine size={"1.5rem"} color="#3ba43b" />
          </div>
          <div className="line">
            <input
              type="text"
              id="to-adress"
              name="to_adress"
              autoComplete="on"
              placeholder="ADRESSE D'ARRIVEE"
            />{" "}
            <BiSolidTaxi size={"1.5rem"} color="#3ba43b" />
          </div>
        </div>

        <textarea
          type="text"
          id="text"
          name="message"
          placeholder="Besoin de préciser quelque chose ?"
          size="20"
        />

        <input className="send-button" type="submit" value="ENVOYER" />
        <div className="recaptcha">
          <ReCAPTCHA
            ref={recaptcha}
            sitekey={process.env.REACT_APP_SITE_SECRET}
          />
        </div>
      </form>
    </div>
  );
}

export default Reservation;
