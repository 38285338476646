const SliderData = [
  {
    image: "/events/aeroport2.jpeg",
  },
  {
    image: "/events/hotel-lille.jpeg",
  },
  {
    image: "/events/festival-de-cannes-2.jpeg",
  },
  {
    image: "/events/events.jpeg",
  },
  {
    image: "/events/events2.jpeg",
  },
  {
    image: "/events/events3.jpeg",
  },
  {
    image: "/events/events4.jpeg",
  },
  {
    image: "/events/events5.jpeg",
  },
  {
    image: "/events/events6.jpeg",
  },
  {
    image: "/events/events7.jpeg",
  },
  {
    image: "/events/events8.jpeg",
  },

  {
    image: "/events/festival-de-cannes-3.jpeg",
  },
  {
    image: "/events/badges.jpeg",
  },
  {
    image: "/events/aeroport.jpeg",
  },
  {
    image: "/events/festival-de-cannes.jpeg",
  },
  {
    image: "/events/trouville.jpeg",
  },
  {
    image: "/events/festival-de-deauville-2.jpeg",
  },
  {
    image: "/events/festival-de-deauville.jpeg",
  },
];

export default SliderData;
